import React from 'react';
import { Card, Row } from 'react-bootstrap';
import { ButtonFields, ButtonResetFields, FormFields, InputFields, InputFileFields, Loading } from '../../components/Index';
import { createUserSchema } from '../../utils/validations/PersonValidation'
import { crearEmpleado } from '../../services/PersonServices';
import { showErrorAlert, showSuccessAlert, showConfirAlert } from '../../utils/helper/AlertService';

function CreateEmployees() {

    const onClickSend = (model, onSubmitProps) => {
        console.log(model);
        // showConfirAlert({
        //     title: "Desea registrar un nuevo empleado",
        //     text: "Verifique que todos los campos esten registrados correctamente",
        //     preConfirm: () => {
        //         crearEmpleado(model).then(() => {
        //             showSuccessAlert('Registro Exitoso');
        //             onSubmitProps.setSubmitting(false)
        //             onSubmitProps.resetForm();
        //         }).catch((error) => {
        //             showErrorAlert('Hubo un problema de registro',  error.apiMessage || 'Error desconocido');
        //         });
        //     }
        // });
    }

    return (
        <div>
            <Row>
                <div className='col-12'>
                    <Card>
                        <Card.Body>
                                <FormFields
                                    initialValues = {{
                                        primerNombre:'',
                                        segundoNombre:'',
                                        apellidoPaterno:'',
                                        apellidoMaterno:'',
                                        email:'',
                                        nroDOI:'',
                                        direccion:'',
                                        telefono:'',
                                        password:'',
                                        confirmPassword:'',
                                        fotoPerfil: {
                                            contenido:"",
                                            extension:""
                                        }

                                    }}
                                    onSubmit = {(values, onSubmitProps) => onClickSend(values,onSubmitProps)}
                                    validationSchema = {createUserSchema}
                                >
                                <div className='row'>
                                    <div className='col-12'>
                                        <h4 className="card-title">Registrar nuevo Usuario</h4>
                                    </div>
                                    <div className='col-sm-12 col-md-12 col-lg-3'>
                                        <InputFields
                                            label={"Apellido Paterno"}
                                            type="text"
                                            name="apellidoPaterno"
                                            size = "sm"
                                        />
                                    </div>
                                    <div className='col-sm-12 col-md-12 col-lg-3'>
                                        <InputFields
                                            label={"Apellido Materno"}
                                            type="text"
                                            name="apellidoMaterno"
                                            size = "sm"
                                        />
                                    </div>
                                    <div className='col-sm-12 col-md-12 col-lg-3'>
                                        <InputFields
                                            label={"Nombre"}
                                            type="text"
                                            name="primerNombre"
                                            size = "sm"
                                        />
                                    </div>
                                    <div className='col-sm-12 col-md-12 col-lg-3'>
                                        <InputFields
                                            label={"Segundo Nombre"}
                                            type="text"
                                            name="segundoNombre"
                                            size = "sm"
                                            isRequired = {false}
                                        />
                                    </div>
                                    <div className='col-sm-12 col-md-12 col-lg-3'>
                                        <InputFields
                                            label={"Número de DNI"}
                                            type="text"
                                            name="nroDOI"
                                            size = "sm"
                                        />
                                    </div>
                                    <div className='col-sm-12 col-md-12 col-lg-3'>
                                        <InputFields
                                            label={"Celular"}
                                            type="text"
                                            name="telefono"
                                            size = "sm"
                                        />
                                    </div>
                                    <div className='col-sm-12 col-md-12 col-lg-6'>
                                        <InputFields
                                            label={"Dirección"}
                                            as="textarea" 
                                            rows={1}
                                            name="direccion"
                                            size = "sm"
                                        />
                                    </div>
                                    <div className='col-sm-12 col-md-12 col-lg-3'>
                                        <InputFields
                                            label={"Email"}
                                            type="email"
                                            name="email"
                                            size = "sm"
                                        />
                                    </div>
                                    <div className='col-sm-12 col-md-12 col-lg-3'>
                                        <InputFields
                                            label={"Contraseña"}
                                            type="password"
                                            name="password"
                                            size = "sm"
                                        />
                                    </div>
                                    <div className='col-sm-12 col-md-12 col-lg-3'>
                                        <InputFields
                                            label={"Confirmación Contraseña"}
                                            type="password"
                                            name="confirmPassword"
                                            size = "sm"
                                        />
                                    </div>
                                    <div className='col-sm-12 col-md-12 col-lg-3'>
                                        <InputFileFields 
                                            label={"Foto Empleado"}
                                            name={"fotoPerfil"}
                                        />
                                    </div>

                                    <div className='col-12 mt-4'>
                                        <ButtonFields/>
                                        <ButtonResetFields/>
                                    </div>
                                </div>
                                    
                                </FormFields>
                            <Loading/>
                        </Card.Body>
                    </Card>
                </div>
            </Row>
        </div>
    );
}

export default CreateEmployees;